import React from 'react';
import { useEffect, useState } from 'react';
import { ethers } from 'ethers';
import axios from 'axios';
import QRCode from 'react-qr-code';
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import "./App.css";
import abi from './abi';
import ProgressBar from 'react-bootstrap/ProgressBar';


function App() {

  const [currentAccount, setCurrentAccount] = useState(null);
  const [contractAddress, setContractAddress] = useState("null");
  const [rate, setRate] = useState(null);
  const [title, setTitle] = useState(null);
  const [description, setDescription] = useState(null);
  const [tokenName, setTokenName] = useState(null);
  const [tokenContract, setTokenContract] = useState(null);
  const [tokenSymbol, setTokenSymbol] = useState(null);
  const [tokenSupply, setTokenSupply] = useState(null);
  const [presaleTokens, setPresaleTokens] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [payAmount, setPayAmount] = useState(null);
  const [remaingTokens, setRemaingTokens] = useState(0);
  const [tokenDecimals, setTokenDecimals] = useState(18);
  const [affiliateCommission, setAffiliateCommission] = useState("0");
  const [adminWallet, setAdminWallet] = useState("0x");

  function getQueryParams() {
    const search = window.location.search.substring(1);
    const params = search.split('&');
    const queryParams = {};
    for (let i = 0; i < params.length; i++) {
      const pair = params[i].split('=');
      const key = decodeURIComponent(pair[0]);
      const value = decodeURIComponent(pair[1]);
      queryParams[key] = value;
    }
    return queryParams;
  }
  const queryParams = getQueryParams();
  const paramValue = queryParams.aff;


  const fetchData = async () => {
    try {
      const response = await axios.get('data.json');
      setContractAddress(response.data[0].value);
      setRate(response.data[1].value);
      setTitle(response.data[2].value);
      setDescription(response.data[3].value);
      setTokenName(response.data[4].value);
      setTokenContract(response.data[5].value);
      setTokenSymbol(response.data[6].value);
      setTokenDecimals(Number(response.data[7].value));
      setTokenSupply(response.data[8].value);
      setPresaleTokens(response.data[9].value);
      setStartTime(response.data[10].value);
      setEndTime(response.data[11].value);
      setAffiliateCommission(response.data[12].value);
      setAdminWallet(response.data[13].value);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleAmountChange = (event) => {
    setPayAmount(event.target.value);
    if(Number(event.target.value) > 0){
    }
  };

  const getRemainingTokens = async() => {
    try{
      const { ethereum } = window;

      if(ethereum){
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(contractAddress, abi, signer);

        let tokensNum = String(await contract.tokenBalance())
        tokensNum = tokensNum.substring(0,tokensNum.length - tokenDecimals);
        setRemaingTokens(Number(tokensNum));
      }
    }catch(err){
		alert("You need to connect with MetaMask.");
    	console.log(err);
    }

  }

  function isEthereumAddress(address) {
    // Ethereum address pattern
    const ethereumAddressPattern = /^(0x)?[0-9a-fA-F]{40}$/;
  
    // Check if the address matches the pattern
    return ethereumAddressPattern.test(address);
  }
  
  function isAdmin(){
    if(adminWallet === null || currentAccount === null){
      return false;
    }
    if(adminWallet.toLowerCase() === currentAccount.toLowerCase()){
      return true;
    }
    return false;
  }


  const checkWalletIsConnected = () => {
    const { ethereum } = window;
    if(!ethereum){
      console.log("Metamask NOT Installed");
      return;
    }else{
      console.log("Metamask Installed");
    }
   }

  const connectWalletHandler = async() => { 
    const { ethereum } = window;
    if(!ethereum){
      alert("Please Install Metamask!");
    }

    try{
      const accounts = await ethereum.request({method: 'eth_requestAccounts'});
      console.log("Found an account :", accounts[0]);
      setCurrentAccount(accounts[0]);
      getRemainingTokens();
    }catch (err){
      console.log(err);
    }
  }

  const connectWalletButton = () => {
    return (
      <button className="btn btn-wallet" onClick={connectWalletHandler}>
        WALLET CONNECT
      </button>
    )
  }

  const createButton = () => {
    return (
      <button className="btn btn-wallet" onClick={buyTokenHandler}>
        BUY {tokenSymbol}
      </button>
    )
  }

  useEffect(() => {
    checkWalletIsConnected();
    fetchData();
  }, [])

  const withdrawTokens = async() => {
    // alert(contractAddress);
    try{
      const { ethereum } = window;

      if(ethereum){
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(contractAddress, abi, signer);

        console.log("Intialize withdraw");
        let withdrawRes = await contract.withdrawTokens(adminWallet);
        
        if(withdrawRes){
          alert("Congratulations, you will receive your tokens very soon");
        }else{
          alert("Something wrong, Only the admin wallet who can withdraw.");
        }
          }
        }catch(err){
        alert("Something wrong, Only the admin wallet who can withdraw.");
          console.log(err);
        }

  }

  
  const buyTokenHandler = async() => {

    if(Number(payAmount) <= 0 || payAmount === null){
      alert("Error, Enter amount of more than 0 BNB");
      return false;
    }

    try{
      const { ethereum } = window;

      if(ethereum){
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(contractAddress, abi, signer);

        console.log("Intialize payment");
        let getadrp;
        const finalPayAmount = String(Number(payAmount) * 1000000000000000000);
        // alert(paramValue);
        if(isEthereumAddress(paramValue)){
          // alert("i'm affiliate");
          getadrp = await contract.buyTokensAff(paramValue, {value: finalPayAmount});
        }else{
          // alert("i'm without affiliate");
          getadrp = await contract.buyTokens({value: finalPayAmount});
        }
        

        if(getadrp){
          alert("Congratulations, you will receive your tokens very soon");
        }else{
          alert("Something wrong, Maybe you don't have enough balance for the transaction.");
        }
          }
        }catch(err){
        alert("Something wrong, Maybe you don't have enough balance for the transaction.");
          console.log(err);
        }

  }



  return (
    <div>
      <div className="container my-4">
        <div className="d-flex gap-2 justify-content-center border-t p-4 align-item-center ">
          <div>
            <img
              src="logo.png"
              className="img-fluid bit-h"
              alt=""
            />
          </div>
          <div className="d-flex align-item-center">
            <h1 className="m-0 bit-h d-flex align-item-center">
              {title}
            </h1>
          </div>
        </div>

        <div className="mt-3">
          <p className="bit-p">
            {description}
          </p>
        </div>

        <div className="row mb-5">
          <div className="col-lg-8 col-md-6 col-12">
            <div className="table-responsive h-100 overflow-y-hidden">
              <table className="table h-100 border-s">
                <tbody>
                  <tr>
                    <th scope="row">
                      <div className="h-100 d-flex align-item-center">
                        <p className="m-0 tb-para">Presale Address:</p>
                      </div>
                    </th>
                    <td>
                      <div className="h-100 d-flex align-item-center">
                        <p className="m-0">
                          {contractAddress}
                        </p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <div className="h-100 d-flex align-item-center">
                        <p className="m-0 tb-para">Token Name:</p>
                      </div>
                    </th>
                    <td>
                      <div className="h-100 d-flex align-item-center">
                        <p className="m-0">{tokenName}</p>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <th scope="row">
                      <div className="h-100 d-flex align-item-center">
                        <p className="m-0 tb-para">Symbol:</p>
                      </div>
                    </th>
                    <td>
                      <div className="h-100 d-flex align-item-center">
                        <p className="m-0">{tokenSymbol}</p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <div className="h-100 d-flex align-item-center">
                        <p className="m-0 tb-para">Token Address:</p>
                      </div>
                    </th>
                    <td>
                      <div className="h-100 d-flex align-item-center">
                        <p className="m-0">
                          {tokenContract}
                        </p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <div className="h-100 d-flex align-item-center">
                        <p className="m-0 tb-para">Token Supply:</p>
                      </div>
                    </th>
                    <td>
                      <div className="h-100 d-flex align-item-center">
                        <p className="m-0">{Number(tokenSupply).toLocaleString()}</p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <div className="h-100 d-flex align-item-center">
                        <p className="m-0 tb-para">Presale Start Time:</p>
                      </div>
                    </th>
                    <td>
                      <div className="h-100 d-flex align-item-center">
                        <p className="m-0">{startTime}</p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <div className="h-100 d-flex align-item-center">
                        <p className="m-0 tb-para">Presale End Time:</p>
                      </div>
                    </th>
                    <td>
                      <div className="h-100 d-flex align-item-center">
                        <p className="m-0">{endTime}</p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <div className="h-100 d-flex align-item-center">
                        <p className="m-0 tb-para">Affiliate Commission:</p>
                      </div>
                    </th>
                    <td>
                      <div className="h-100 d-flex align-item-center">
                        <p className="m-0">Earn %{affiliateCommission} Commission per Sale</p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <div className="h-100 d-flex align-item-center">
                        <p className="m-0 tb-para">Affiliate Link:</p>
                      </div>
                    </th>
                    <td>
                      <div className="h-100 d-flex align-item-center">
                        <p className="m-0">
                          {window.location.href}{currentAccount ? "?aff=" + currentAccount : "?aff=YOUR-WALLET-ADDRESS"}
                        </p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12 mt-lg-0 mt-md-0 mt-4 ">
            <div className="card-d">
              <h5 className="font-23">MetaMask Buy</h5>
              <p className="font-16 mt-3">
                <span className="font-w-700">Rate :</span> 1 BNB ={" "}
                <span className="font-w-700">{Number(rate).toLocaleString()} {tokenSymbol}</span>
              </p>
              <p className="font-16">
                <span className="font-w-700">Tokens For Presale:</span> {Number(presaleTokens).toLocaleString()}
              </p>
              {currentAccount &&
                <ProgressBar now={String(((presaleTokens-remaingTokens)/presaleTokens)*100)} label={`${String(Math.round((((presaleTokens-remaingTokens)/presaleTokens))*100))}%`} />
              }
              
              <input
                type="text"
                className="input-d mt-3"
                placeholder="Amount in BNB"
                value={payAmount} 
                onChange={handleAmountChange}
              />
              <p className="mt-3 font-16">
                <span className="font-w-700">Get :</span> {payAmount * rate} {tokenSymbol}
              </p>
              {currentAccount ? createButton() : connectWalletButton()}
            </div>

            <div className="card-d mt-4">
              <h5 className="font-23">Direct Buy</h5>
              <p className="font-16">
                Send BNB direct to presale address and you will receive {tokenSymbol} tokens immediatly.
              </p>
              <div className="py-3" align="center">
                <QRCode
                  title="Presale QR"
                  value={contractAddress}
                />
              </div>
            </div>

            {isAdmin() && 
            <div className="card-d mt-4">
              <h5 className="font-23">Admin Area</h5>
              <p className="font-16">
                Admin wallet only who can withdraw the tokens from the presale contract.
              </p>
              <button className="btn btn-wallet" onClick={withdrawTokens}>
                Withdraw Tokens
              </button>
            </div>}

          </div>
        </div>
      </div>
      <footer className="py-4 footer-shadow">
        <p className="text-center m-0">© {new Date().getFullYear()} Powered by <a href='https://besugo.io/' target='_blank' rel="noreferrer">Besugo</a></p>
      </footer>
    </div>
  );
}

export default App;
